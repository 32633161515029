<!-- 就餐服务 就餐账单 项目名称详情 -->
<template>
    <div class="meeting-info">
        <div v-if="!errorShow">
            <loading :loadingShow="loadingShow"></loading>
            <div v-if="!loadingShow">
                <global-page-back
                    :detailPageTitle="pageTitle"
                    @handlerGlobalType="handleBack"
                >
                </global-page-back>
                
                <div class="filter_disFlex">
                    <expand-filter
                        :formData="formData"
                        @clickBtn="clickBtn"
                        marginBottom="0px"
                        @changeBtnFormType="changeBtnFormType"
                        :closeWidth="900"
                        @changeSel="changeSel"
                    ></expand-filter>
                    <div class="filter_Btn">
                        <el-button
                            v-if="arrtPushBtn"
                            @click="acts()"
                            type="primary"
                            v-hasPermi="[
                                'repastBill:billDetail:batchPushBill',
                            ]"
                            >批量推送账单</el-button
                        >
                        <el-button
                            :loading="exportLoading"
                            v-hasPermi="['repastBill:billDetail:export']"
                            @click="handleExport()"
                            >导出</el-button
                        >
                    </div>
                </div>
                <div class="dis_left">
                    <div class="mr30">应缴总金额：{{ payableAmount }}元</div>
                    <div class="mr30">已缴总金额：{{ payAmount }}元</div>
                    <div>未缴总金额：{{ unPayAmount }}元</div>
                </div>
                <table-data
                    :config="table_config"
                    :tableData="table_data"
                    v-loading="loadingTable"
                    backgroundHeader="#FAFBFC"
                    :headerStyle="{height: '50px'}"
                    :rowStyle="{height: '50px'}"
                    ref="table"
                    id="table"
                    @handleSelectionChange="handleSelectionChange"
                >
                    <template v-slot:operation="slotData">
                        <xk-button
                            v-if="slotData.data.showbtn"
                            @click="handelPush(slotData.data)"
                            v-hasPermi="[
                                'repastBill:billDetail:singlePushBill',
                            ]"
                            size="mini"
                            style="
                                color: #3C7FFF;
                                border: none;
                                background: none;
                            "
                        >
                            推送账单
                        </xk-button>
                    </template>
                </table-data>
                <pagination
                    :total="total"
                    :page.sync="listQuery.pageNum"
                    :limit.sync="listQuery.pageRow"
                    @pagination="getList()"
                />
            </div>
        </div>
        <error
            v-else
            :errorShow="errorShow"
        ></error>
    </div>
</template>

<script>
import {
    FilterData,
    Loading,
    Pagination,
    DialogWrapper,
    debounce,
} from "common-local";
import ExpandFilter from "../Sub/ExpandFilter.vue";
import TableData from "../Sub/TableData.vue";
import selectTree from "../Sub/selectTree";
import {CategoryManagementModel} from "@/models/SServiceSchReport";
import {CustodialServicesModel} from "@/models/RepastServices";
import ImportState from "./ImportState/index.vue";
import {downloadFile} from "@/libs/utils";
// 逻辑运算
import system from "@/operations/system.js";
import GlobalPageBack from '../Sub/GlobalPageBack/index.vue';
export default {
    name: "TrusteeshipBillInfo",
    components: {
        selectTree,
        FilterData,
        Loading,
        TableData,
        Pagination,
        ExpandFilter,
        ImportState,
        DialogWrapper,
        GlobalPageBack
    },
    props: {
        detailData: {
            type: Object,
            default: "",
        },
    },
    data() {
        return {
            loadingShow: false,
            errorShow: false,
            loadingTable: false,
            exportLoading: false,
            total: 0,
            depositTime: "",
            arrtPushBtn: false,
            listQuery: {
                pageNum: 1,
                pageRow: 20,
                schoolId: this.$store.state.schoolId,
                gradeId: "",
                classId: "",
                studentName: "",
                onlyCode: "",
                studyNo: "",
                payStatus: "",
                depositId: this.detailData.data.id,
            },
            // 统计数据
            payableAmount: "", // 应缴金额
            payAmount: "", // 已缴金额
            unPayAmount: "", // 未缴金额
            // 头部筛选
            formData: {
                btnFormType: true,
                formInline: {
                    teacherName: "",
                },
                data: [
                    {
                        type: "select",
                        label: "",
                        value: "",
                        placeholder: "年级",
                        key: "gradeId",
                        list: [],
                    },
                    {
                        type: "select",
                        label: "",
                        value: "",
                        placeholder: "班级",
                        key: "classId",
                        list: [],
                    },
                    {
                        type: "select",
                        label: "",
                        placeholder: "缴费状态",
                        key: "payStatus",
                        list: [
                            {
                                value: "1",
                                label: "待缴费",
                            },
                            {
                                value: "2",
                                label: "缴费中",
                            },
                            {
                                value: "3",
                                label: "已缴费",
                            },
                        ],
                    },
                    {
                        type: "input",
                        label: "",
                        value: "",
                        placeholder: "输入姓名",
                        key: "studentName",
                    },
                    {
                        type: "input",
                        label: "",
                        value: "",
                        placeholder: "输入唯一号",
                        key: "onlyCode",
                    },
                    {
                        type: "input",
                        label: "",
                        value: "",
                        placeholder: "输入学号",
                        key: "studyNo",
                    },
                ],
                btnList: [
                    {
                        type: "primary",
                        text: "查询",
                        fn: "primary",
                        auth: ["repastBill:billDetail:list"],
                    },
                    {
                        type: "enquiry",
                        text: "重置",
                        fn: "reset",
                    },
                ],
            },
            table_config: {
                thead: [
                    {
                        label: "姓名",
                        prop: "studentName",
                        className: 'text-spacing-reduction'
                    },
                    {
                        label: "年级",
                        prop: "gradeName",
                        className: 'text-spacing-reduction'
                    },
                    {
                        label: "班级",
                        prop: "className",
                        className: 'text-spacing-reduction'
                    },
                    {
                        label: "学号",
                        prop: "studyNo",
                        labelWidth: "150",
                        className: 'text-spacing-reduction'
                    },
                    {
                        label: "唯一号",
                        prop: "onlyCode",
                        labelWidth: "150",
                    },
                    {
                        label: "缴费状态",
                        prop: "payStatus",
                        type: "function",
                        fixed: false,
                        callBack: (row, prop) => {
                            switch (row.payStatus) {
                                case "1":
                                    return "待缴费";
                                case "2":
                                    return "缴费中";
                                case "3":
                                    return "已缴费";
                            }
                        },
                    },
                    {
                        label: "就餐次数",
                        prop: "depositDay",
                        fixed: false,
                    },
                    {
                        label: "应缴金额",
                        prop: "payableAmount",
                        fixed: false,
                    },
                    {
                        label: "缴费时间",
                        prop: "payTime",
                        labelWidth: "190",
                    },
                    {
                        label: "操作",
                        type: "slot",
                        slotName: "operation",
                        labelWidth: "130",
                        className: 'text-spacing-reduction'
                    },
                ],
                check: true,
                checkSelectAble: (row) => {
                    return row.payStatus === "1";
                },
                height: "",
            },
            table_data: [],
            showbtn: false,
            gradeOptions: [], // 年级下拉框数据
            classOptions: [], // 班级下拉框数据
            studentOptions: [], // 学生下拉框数据
            tableListSel: [], // 批量推送
        };
    },
    created() {
        this.init();
        this.getSummary();
        this.getList();
        this.getGradList();
    },
    computed: {
        backImg() {
            return require("@/assets/images/back3.png");
        },
        pageTitle() {
            return this.detailData.data.title + '('+ this.depositTime + ')'
        }
    },
    mounted() {
        this.table_config.height =
            document.body.clientHeight - this.$refs.table.$el.offsetTop - 98;
            this.$eventDispatch('setGlobalPageType', 'form')
    },
    activated() {
        this.$eventDispatch('setGlobalPageType', 'form')
    },
    methods: {
        init() {
            this.$eventDispatch('setGlobalPageType', 'form');
            // 外层推送了账单才显示
            // 为 已发送 才显示
            // console.log('this.detailData.data',this.detailData.data);
            if (
                this.detailData.data.billStatus == "2" &&
                this.detailData.data.payStatus !== "3"
            ) {
                // 已缴费
                this.arrtPushBtn = true;
            } else {
                this.arrtPushBtn = false;
            }
            this.gradeOptions = this.detailData.data.menuList.map((i) => ({
                label: i.name,
                value: i.id,
            }));
            this.formData.data.forEach((item) => {
                if (item.key === "gradeId") {
                    item.list = this.gradeOptions
                }
            });
            this.depositTime =
                this.detailData.data.depositStartDate.split(" ")[0] +
                " — " +
                this.detailData.data.depositEndDate.split(" ")[0];
        },
        /**
         * @Description: 返回上一页
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 闫乔
         * @Date: 2023-04-23 15:33:52
         */
        handleBack() {
            this.$emit("toList", {
                //   productId: this.detailData.productId
            });
            this.$eventDispatch('setGlobalPageType', 'list')
        },
        /**
         * @Description: 查询账单汇总
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 闫乔
         * @Date: 2023-04-25 14:15:00
         */
        async getSummary() {
            await this._fet("/school/repastStudent/getSummary", this.listQuery)
                .then((res) => {
                    if (res.data.code == "200") {
                        this.payableAmount = res.data.data.totalPayableAmount;
                        this.payAmount = res.data.data.totalPayAmount;
                        this.unPayAmount = res.data.data.totalUnPayableAmount;
                    } else if (res.data && res.data.msg) {
                        this.$message.error(res.data.msg);
                    }
                })
                .catch(() => {});
        },
        /**
         * @Description: 获取列表数据
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 闫乔
         * @Date: 2023-04-23 15:33:33
         * @param {*} isPaging
         */
        getList(isPaging) {
            if (isPaging) {
                this.listQuery.pageNum = 1;
            }
            let detailForm = {
                pageNum: this.listQuery.pageNum,
                pageRow: this.listQuery.pageRow,
                schoolId: this.$store.state.schoolId,
                gradeId: this.listQuery.gradeId,
                classId: this.listQuery.classId,
                studentName: this.listQuery.studentName,
                onlyCode: this.listQuery.onlyCode,
                studyNo: this.listQuery.studyNo,
                payStatus: this.listQuery.payStatus,
                depositId: this.detailData.data.id,
                openType: 1,
            };

            this._fet("/school/repastStudent/lit4page", detailForm).then(
                (res) => {
                    if (res.data.code == "200") {
                        this.table_data = res.data.data.rows;
                        this.total = res.data.data.total;
                        res.data.data.rows.forEach((i) => {
                            i.showbtn = true;
                        });
                        res.data.data.rows.forEach((item) => {
                            // 外层的为  已缴费  不显示
                            if (this.detailData.data.payStatus == "3") {
                                item.showbtn = false;
                            } else {
                                // 没有缴费

                                // 外层的状态为 未发送 不显示
                                if (this.detailData.data.billStatus == "1") {
                                    item.showbtn = false;
                                } else {
                                    // 已发送

                                    // 当前页面为  已缴费  不显示
                                    if (item.payStatus == "3") {
                                        item.showbtn = false;
                                    }
                                    // 当前页面为  应缴金额为0  不显示
                                    if (item.payableAmount == 0) {
                                        item.showbtn = false;
                                    }
                                }
                            }
                        });
                    } else if (res.data && res.data.msg) {
                        this.$message.error(res.data.msg);
                    }
                },
            );
        },
        /**
         * @Description: 获取年级下拉数据
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 闫乔
         * @Date: 2023-04-23 15:32:39
         */
        // getGradList() {
        //     const custodialServicesModel = new CustodialServicesModel();
        //     custodialServicesModel
        //         .getYearDropDownList2({
        //             schoolId: this.$store.state.schoolId,
        //         })
        //         .then((res) => {
        //             if (res.data.code === "200") {
        //                 // console.log(res, "0000");
        //                 // this.gradeOptions = res.data.data.map(i => ({ label: i.name, value: i.id }))
        //                 this.formData.data.forEach((item) => {
        //                     if (item.key === "gradeId") {
        //                         item.list = res.data.data.list.filter(i => i.organType == '3').map((i) => ({
        //                             label: i.name,
        //                             value: i.id,
        //                         }));
        //                         // item.list.unshift({ label: '全部', value: '' })
        //                     }
        //                 });
        //             } else if (res.data && res.data.msg) {
        //                 this.$message.error(res.data.msg);
        //             }
        //         });
        // },
        /**
         * @Description: 获取班级下拉数据
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 闫乔
         * @Date: 2023-04-23 15:32:26
         * @param {*} gradeId
         */
        getClassList(gradeId) {
            const classIndex = this.formData.data.findIndex(
                (i) => i.key === "classId",
            );
            this.formData.data[classIndex].value = "";
            this.formData.data[classIndex].list = [];
            this.listQuery.classId = "";
            this.classOptions = [];
            this.formData.data.forEach((item) => {
                if (item.key === 'classId') {
                    item.list = this.detailData.data.menuList.filter(i => i.id === gradeId)[0].child.map(i => ({ label: i.name, value: i.id }))
                }
            })
            // if (gradeId) {
            //     const custodialServicesModel = new CustodialServicesModel();
            //     custodialServicesModel
            //         .getClassDropDownList({
            //             schoolId: this.$store.state.schoolId,
            //             gradeId: gradeId,
            //         })
            //         .then((res) => {
            //             if (res.data.code === "200") {
            //                 // console.log(res, "1111");
            //                 // this.classOptions = res.data.data.map(i => ({ label: i.name, value: i.id }))
            //                 this.formData.data.forEach((item) => {
            //                     if (item.key === "classId") {
            //                         item.list = res.data.data.map((i) => ({
            //                             label: i.name,
            //                             value: i.id,
            //                         }));
            //                         // item.list.unshift({ label: '全部', value: '' })
            //                     }
            //                 });
            //             } else if (res.data && res.data.msg) {
            //                 this.$message.error(res.data.msg);
            //             }
            //         });
            // }
        },
        // 修改选择
        changeSel(data) {
            switch (data.key) {
                case "gradeId":
                    this.getClassList(data.value);
                    break;
                default:
                    break;
            }
        },

        /**
         * @Description: 展开 / 收起
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 闫乔
         * @Date: 2023-04-23 15:30:23
         */
        changeBtnFormType() {
            this.formData.btnFormType = !this.formData.btnFormType;
            this.$nextTick(() => {
                this.table_config.height =
                    document.body.clientHeight -
                    document.getElementById("table").offsetTop -
                    136;
            });
        },
        /**
         * @Description: 查询 / 重置
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 闫乔
         * @Date: 2023-04-23 15:29:58
         * @param {*} type
         */
        clickBtn: debounce(function (type) {
            switch (type.item.fn) {
                case "primary": // 查询
                    this.formData.data.forEach((item) => {
                        this.listQuery[item.key] = item.value;
                    });
                    this.getList(1);
                    this.getSummary();
                    break;
                case "reset": // 重置
                    this.formData.data.forEach((item) => {
                        item.value = "";
                        this.listQuery[item.key] = "";
                    });
                    this.getList(1);
                    this.getSummary();
                    break;

                default:
                    break;
            }
        }, 200),

        /**
         * @Description: 推送账单按钮
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 闫乔
         * @Date: 2023-04-23 15:28:58
         * @param {*} data
         */
        handelPush: debounce(function (data) {
            // console.log("当前data", data);
            this.$confirm("会再次给家长推送账单，是否确认推送？", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            })
                .then(() => {
                    this._fet("/school/schoolRepast/sendStuPushBillMessage", {
                        idList: [data.id],
                    }).then((res) => {
                        if (res.data.code == "200") {
                            this.$message.success("发送成功");
                            this.getList();
                            this.getSummary();
                        } else if (res.data && res.data.msg) {
                            this.$message.error(res.data.msg);
                        }
                    });
                })
                .catch(() => {});
        }, 200),

        /**
         * @Description: 导出
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 闫乔
         * @Date: 2023-04-23 16:53:07
         */
        handleExport: debounce(function () {
            this.exportLoading = true;
            let form = {
                schoolId: this.$store.state.schoolId,
                gradeId: this.listQuery.gradeId,
                classId: this.listQuery.classId,
                studentName: this.listQuery.studentName,
                onlyCode: this.listQuery.onlyCode,
                studyNo: this.listQuery.studyNo,
                payStatus: this.listQuery.payStatus,
                depositId: this.detailData.data.id,
            };

            downloadFile(
                {
                    url: "/school/repastStudent/export",
                    form,
                },
                () => {
                    this.$message.success("导出成功");
                    this.exportLoading = false;
                },
                () => {
                    this.exportLoading = false;
                },
            );
        }, 200),
        // 选中
        handleSelectionChange(data) {
            console.log(data, "data");
            this.tableListSel = data;
        },
        // 批量推送账单
        acts: debounce(function () {
            if (this.tableListSel.length === 0) {
                return this.$message.warning("未选择推送学生信息！");
            }
            this.$confirm(
                `会再次给所选学生家长推送账单，是否确认推送?`,
                "提示",
                {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning",
                },
            )
                .then(() => {
                    // .map(it => it.id)
                    this._fet("/school/schoolRepast/sendStuPushBillMessage", {
                        idList: this.tableListSel.map((it) => it.id),
                    }).then((r) => {
                        if (r.data.code == 200) {
                            this.$message.success("发送成功");
                            this.getList();
                            this.getSummary();
                        } else {
                            this.$message.error(r.data.msg);
                        }
                    });
                })
                .catch(() => {});
        }, 200),
    },
};
</script>

<style lang="scss" scoped>
.body-title {
    display: flex;
    background-color: #ffffff;
    align-items: center;
    margin-top: 8px;
    box-sizing: border-box;
    width: 100%;
    padding-left: 20px;
    height: 48px;
    border-bottom: 1px solid #edf0f2;

    .back {
        height: 14px;
        line-height: 14px;
        display: flex;
        align-items: center;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #2b2f33;
        cursor: pointer;

        &:hover {
            color: #3C7FFF;
        }

        .back-img {
            width: 10px;
            margin-right: 8px;

            &:hover {
                color: #3C7FFF;
            }
        }
        i {
            color: #363a40;
            &:hover {
                color: #3C7FFF;
            }
        }
    }

    .title {
        font-weight: bold;
        margin-left: 20px;
        font-size: 14px;
    }

    .return-btn {
        display: flex;
        color: #363a40;
        cursor: pointer;
        font-size: 14px;
        align-items: center;

        &:hover {
            color: #3C7FFF;
        }

        i {
            color: #363a40;
            margin-right: 5px;

            &:hover {
                color: #3C7FFF;
            }
        }
    }
}
.filter_disFlex {
    display: flex;
    background-color: white;
    justify-content: space-between;
}

.filter_Btn {
    flex-shrink: 0;
    padding-right: 20px;
    margin-top: 10px;

    .mr20_btn {
        margin-right: 20px;
    }
}
.dis_left {
    padding: 10px 20px;
    background-color: #fff;
    font-size: 14px;
    font-weight: 700;
    display: flex;
    .mr30 {
        margin-right: 30px;
    }
}
</style>
